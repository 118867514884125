import { UUID } from 'node:crypto';
import { Response } from 'superagent';

import ApiInstance from '../../../bi/api';

import { documentTemplates } from './stores/documentTemplates';

import { LABELS } from '../../pages/Company/components/DocumentTemplates/consts';

import { IRegistry } from './types';

const ERROR = 'Неизвестная ошибка';

export class Company {
  documentTemplates = documentTemplates;

  loadTemplatesRaw = async (companyId: number) => {
    const templates = await ApiInstance.Company.loadListRegistryTemplates(companyId);

    this.documentTemplates.setTemplates(templates);
  };

  uploadFile = async (file: File, companyId: number) => {
    if (this.documentTemplates.templates.length === 3) {
      return this.documentTemplates.setError(LABELS.ERRORS.TEMPLATE_LIMIT);
    }

    this.documentTemplates.setLoading(true);

    try {
      await ApiInstance.Company.uploadRegistryTemplates(companyId, file);
      await this.loadTemplatesRaw(companyId);
    } catch (e: unknown) {
      if (e instanceof Response) {
        this.documentTemplates.setError(e.body ? e.body : ERROR);
      }
    } finally {
      this.documentTemplates.setLoading(false);
    }
  };

  getFilePreview = async (rId: UUID, format: string) => {
    try {
      await ApiInstance.Company.downloadRegistry(rId, format);
    } catch (e) {
      if (e instanceof Response) {
        this.documentTemplates.setError(e.body ? e.body : ERROR);
      }
    }
  };

  getTemplate = async (rId: UUID) => {
    try {
      await ApiInstance.Company.downloadTemplate(rId);
    } catch (e) {
      if (e instanceof Response) {
        this.documentTemplates.setError(e.body ? e.body : ERROR);
      }
    }
  };

  loadTemplates = async (companyId: number) => {
    this.documentTemplates.setLoading(true);

    try {
      await this.loadTemplatesRaw(companyId);
    } catch (e) {
      if (e instanceof Response) {
        this.documentTemplates.setError(e.body ? e.body : ERROR);
      }
    } finally {
      this.documentTemplates.setLoading(false);
    }
  };

  removeTemplates = async (ids: UUID[], companyId: number) => {
    this.documentTemplates.setLoading(true);

    try {
      await ApiInstance.Company.removeRegistryTemplates(ids);
      await this.loadTemplatesRaw(companyId);
    } catch (e) {
      if (e instanceof Response) {
        this.documentTemplates.setError(e.body ? e.body : ERROR);
      }
    } finally {
      this.documentTemplates.setLoading(false);
    }
  };

  updateTemplateName = async (name: string, id: UUID, companyId: number) => {
    this.documentTemplates.setLoadingField(id);

    try {
      await ApiInstance.Company.updateRegistryTemplatesName(companyId, id, name);
    } catch (e) {
      if (e instanceof Response) {
        this.documentTemplates.setLoadingField(id);
        this.documentTemplates.setError(e.body ? e.body : ERROR);
      }
    }

    await this.loadTemplatesRaw(companyId);

    return this.documentTemplates.setLoadingField(null);
  };

  loadRegistries = async (companyId: number) => {
    this.documentTemplates.setLoading(true);

    try {
      const registries = await ApiInstance.Company.loadRegistries(companyId);

      this.documentTemplates.setRegistries(registries);
    } catch (e) {
      if (e instanceof Response) {
        this.documentTemplates.setError(e.body ? e.body : ERROR);
      }
    } finally {
      this.documentTemplates.setLoading(false);
    }
  };

  addRegistry = async (registryTemplateId: IRegistry, companyId: number) => {
    this.documentTemplates.setLoading(true);

    try {
      await ApiInstance.Company.addRegistry(companyId, registryTemplateId);
      await this.loadRegistries(companyId);
    } catch (e) {
      if (e instanceof Response) {
        this.documentTemplates.setError(e.body ? e.body : ERROR);
      }
    } finally {
      this.documentTemplates.setLoading(false);
    }
  };

  checkRegistiries = (Id: UUID) => {
    if (this.documentTemplates.registries.find(({ RegistryTemplateId }) => RegistryTemplateId === Id)) {
      this.documentTemplates.setError(LABELS.ERRORS.ALREADY);
    }
  };

  updateRegistryDate = async (constructedRegistryId: UUID, date: string, companyId: number) => {
    this.documentTemplates.setLoading(true);

    try {
      await ApiInstance.Company.updateRegistryDate(constructedRegistryId, date, companyId);
      await this.loadRegistries(companyId);
    } catch (e) {
      if (e instanceof Response) {
        this.documentTemplates.setError(e.body ? e.body : ERROR);
      }
    } finally {
      this.documentTemplates.setLoading(false);
    }
  };

  updateRegistryFileType = async (constructedRegistryId: UUID, fileTypes: string[], companyId: number) => {
    this.documentTemplates.setLoading(true);

    try {
      await ApiInstance.Company.updateRegistryFileType(constructedRegistryId, fileTypes);
      await this.loadRegistries(companyId);
    } catch (e) {
      if (e instanceof Response) {
        this.documentTemplates.setError(e.body ? e.body : ERROR);
      }
    } finally {
      this.documentTemplates.setLoading(false);
    }
  };

  removeRegistry = async (constructedRegistryId: UUID, companyId: number) => {
    this.documentTemplates.setLoading(true);

    try {
      await ApiInstance.Company.removeRegistry(constructedRegistryId);
      await this.loadRegistries(companyId);
    } catch (e) {
      if (e instanceof Response) {
        this.documentTemplates.setError(e.body ? e.body : ERROR);
      }
    } finally {
      this.documentTemplates.setLoading(false);
    }
  };
}

export const company = new Company();
