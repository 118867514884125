import { develop } from './develop';
import { company } from './company';
import { workspace } from './workspace';
import { access } from './access';
import { analytics } from './analytics';
import Account from 'bi/services/account/service';
import Aeroexpress from 'bi/services/aeroexpress/service';
import Air from 'bi/services/air/service';
import airlineCancel from 'bi/services/airlineCancel/service';
import airlineWarning from 'bi/services/airlineWarning/service';
import AmoCRM from 'bi/services/amocrm/service';
import App from 'bi/services/app/service';
import Approval from 'bi/services/approval/service';
import Business from 'bi/services/business/service';
import Calculator from 'bi/services/calculator/service';
import Calls from 'bi/services/calls/service';
import CartsAndNotes from 'bi/services/cartsAndNotes/service';
import Companies from 'bi/services/companies/service';
import Company from 'bi/services/company/service';
import DocumentsConstructor from 'bi/services/documentsConstructor/service';
import Employee from 'bi/services/employee/service';
import Employees from 'bi/services/employees/service';
import Enums from 'bi/services/enums/service';
import FeatureFlags from 'bi/services/featureFlags/service';
import Filter from 'bi/services/filter/service';
import Guid from 'bi/services/guid/service';
import Hotels from 'bi/services/hotels/service';
import hotelWarning from 'bi/services/hotelWarning/service';
import Notifications from 'bi/services/notifications/service';
import Order from 'bi/services/order/service';
import railwayWarning from 'bi/services/railwayWarning/service';
import Regions from 'bi/services/regions/service';
import ReviseActTest from 'bi/services/reviseacttest/service';
import Rights from 'bi/services/rights/service';
import SearchUsers from 'bi/services/seacrhUsers/service';
import SendingDiadoc from 'bi/services/sendingDiadoc/service';
import Statics from 'bi/services/statics/service';
import taxiWarning from 'bi/services/taxiWarning/service';
import trainCancel from 'bi/services/trainCancel/service';
import transferWarning from 'bi/services/transferWarning/service';
import TravelPolicy from 'bi/services/travelPolicy/service';
import Trip from 'bi/services/trip/service';
import TripTags from 'bi/services/tripTags/service';
import Unloading1c from 'bi/services/unloading1c/service';
import User from 'bi/services/user/service';
import vipHallWarning from 'bi/services/vipHallWarning/service';
import Workplace from 'bi/services/workplace/service';

export const mobxServices = {
  develop,
  company,
  workspace,
  access,
  analytics,
};

export const riseServices = {
  Account,
  Aeroexpress,
  Air,
  airlineCancel,
  airlineWarning,
  AmoCRM,
  App,
  Approval,
  Business,
  Calculator,
  Calls,
  CartsAndNotes,
  Companies,
  Company,
  DocumentsConstructor,
  Employee,
  Employees,
  Enums,
  FeatureFlags,
  Filter,
  Guid,
  Hotels,
  hotelWarning,
  Notifications,
  Order,
  railwayWarning,
  Regions,
  ReviseActTest,
  Rights,
  SearchUsers,
  SendingDiadoc,
  Statics,
  taxiWarning,
  trainCancel,
  transferWarning,
  TravelPolicy,
  Trip,
  TripTags,
  Unloading1c,
  User,
  vipHallWarning,
  Workplace,
};

