import {
  action,
  computed,
  makeObservable,
  observable,
} from 'mobx';

import { KEYS } from '../../../../bi/constants/customAnalytics';

export class CustomAnalytics {
  constructor() {
    makeObservable(this);
  }

  @observable accessor _loading = true;
  @observable accessor _suggestsLoading = true;
  @observable accessor analyticsData = [];
  @observable accessor hotelAnalyticsData = {};
  @observable accessor selectedAnalytics = {};
  @observable accessor hotelSelectedAnalytics = {};
  @observable accessor selectedValues = [];
  @observable accessor selectedFilteredValues = {};

  @action
  setLoading = (status: boolean) => {
    this._loading = status;
  };

  @action
  setSuggestsLoading = (status: boolean) => {
    this._suggestsLoading = status;
  };

  @action
  setAnalyticsData = (data) => {
    this.analyticsData = data;
  };

  @action
  setHotelAnalyticsData = (data) => {
    this.hotelAnalyticsData = data;
  };

  @action
  setSelectedValues = (data) => {
    this.selectedValues = data;
  };

  @action
  setSelectedAnalytics = (data) => {
    this.selectedAnalytics = data.reduce((acc, item) => {
      acc[item.UserAnalyticsId] = item.Id;

      return acc;
    }, {});
  };

  @action
  updateSelectedAnalytic = (key, value) => {
    this.selectedAnalytics = {
      ...this.selectedAnalytics,
      [key]: value,
    };
  };

  @action
  removeSelectedAnalytic = (key) => {
    const newAnalytics = { ...this.selectedAnalytics };
    delete newAnalytics[key];
    this.selectedAnalytics = newAnalytics;
  };

  @action
  setHotelSelectedAnalytics = (data, roomKey) => {
    this.hotelSelectedAnalytics = {
      ...this.hotelSelectedAnalytics,
      [roomKey]: data.reduce((acc, item) => {
        acc[item.UserAnalyticsId] = item.Id;

        return acc;
      }, {}),
    };
  };

  @action
  updateHotelSelectedAnalytic = (key, value, roomIndex) => {
    const roomKey = Number.isInteger(roomIndex) && roomIndex >= 0 ? roomIndex : KEYS.GENERAL_ANALYTICS;
    this.hotelSelectedAnalytics = {
      ...this.hotelSelectedAnalytics,
      [roomKey]: {
        ...(this.hotelSelectedAnalytics[roomKey] || {}),
        [key]: value,
      },
    };
  };

  @action
  removeHotelSelectedAnalytic = (key, roomIndex) => {
    const roomKey = Number.isInteger(roomIndex) && roomIndex >= 0 ? roomIndex : KEYS.GENERAL_ANALYTICS;
    if (!this.hotelSelectedAnalytics[roomKey]) return;

    const newAnalytics = { ...this.hotelSelectedAnalytics[roomKey] };
    delete newAnalytics[key];

    this.hotelSelectedAnalytics = {
      ...this.hotelSelectedAnalytics,
      [roomKey]: newAnalytics,
    };
  };

  @action
  updateSelectedFilteredValues = (key, value) => {
    if (!this.selectedFilteredValues[key]) {
      this.selectedFilteredValues[key] = [value];

      return;
    }

    const index = this.selectedFilteredValues[key].findIndex((item) => item.id === value.id);
    if (index > -1) {
      this.selectedFilteredValues[key].splice(index, 1);
      if (this.selectedFilteredValues[key].length === 0) {
        delete this.selectedFilteredValues[key];
      }
    } else {
      this.selectedFilteredValues[key].push(value);
    }
  };

  @computed
  get loading() {
    return this._loading;
  }

  @computed
  get suggestsLoading() {
    return this._suggestsLoading;
  }
}

export const customAnalytics = new CustomAnalytics();
