import Api from '../../../bi/api';
import debounce from 'es6-promise-debounce';
import { customAnalytics } from './stores/customAnalytics';

import { KEYS } from '../../../bi/constants/customAnalytics';

const DEBOUNCE_TIME = 200;
const autocompleteAnalyticsDebounce = debounce(Api.CustomAnalytics.autocompleteAnalytics, DEBOUNCE_TIME);

export class Analytics {
  customAnalytics = customAnalytics;

  getAnalyticsWithoutValues = (accountId) => Api.CustomAnalytics.getAnalyticsWithoutValues(accountId);

  getAnalyticByValueId = async (valueIds) => {
    try {
      this.customAnalytics.setLoading(true);
      const response = await Api.CustomAnalytics.getAnalyticsByValueId(valueIds);
      this.customAnalytics.setAnalyticsData(response);
      this.customAnalytics.setSelectedAnalytics(response);
    } catch (error) {
    } finally {
      this.customAnalytics.setLoading(false);
    }
  };

  getAnalyticByValueIdWithRoomIndex = async (roomIndex, selectedAnalytics) => {
    const key = typeof roomIndex === 'number' ? roomIndex : KEYS.GENERAL_ANALYTICS;
    if (!this.customAnalytics.hotelSelectedAnalytics[key] && selectedAnalytics.length !== 0) {
      this.customAnalytics.setHotelSelectedAnalytics(selectedAnalytics, key);
    }
  };

  autocompleteAnalytics = async (accountId, analyticId, query, setSuggests) => {
    try {
      const response = await autocompleteAnalyticsDebounce(accountId, analyticId, query);
      setSuggests(response.map(({ Name, Id }) => ({ id: Id, name: Name })));
    } catch (error) {
      setSuggests([]);
    }
  };

  extractUserAnalytics = (response) => {
    const rootAnalytics = response.userAnalytics || [];

    const hotelRatesAnalytics = response.hotelRates
      ? response.hotelRates.flatMap((rate) => rate.userAnalytics || [])
      : [];

    this.customAnalytics.setSelectedValues([...rootAnalytics, ...hotelRatesAnalytics]);
    this.getAnalyticByValueId([...rootAnalytics, ...hotelRatesAnalytics]);
  };
}

export const analytics = new Analytics();
